p.p1 {
  margin: 0px 0px 0px 0px;
  text-align: center;
  font: 13.3px Times;
  color: #000000;
  -webkit-text-stroke: #000000;
}
p.p2 {
  margin: 0px 0px 0px 0px;
  text-align: center;
  font: 13.3px "Times New Roman";
  color: #000000;
  -webkit-text-stroke: #000000;
}
p.p3 {
  margin: 0px 0px 0px 0px;
  font: 13.3px "Times New Roman";
  color: #000000;
  -webkit-text-stroke: #000000;
}
p.p4 {
  margin: 0px 0px 16px 0px;
  text-align: justify;
  font: 13.3px "Times New Roman";
  color: #000000;
  -webkit-text-stroke: #000000;
}
p.p5 {
  margin: 0px 0px 16px 0px;
  text-align: justify;
  font: 9.3px "Times New Roman";
  color: #000000;
  -webkit-text-stroke: #000000;
}
p.p6 {
  margin: 0px 0px 16px 0px;
  text-align: justify;
  text-indent: 48px;
  font: 13.3px "Times New Roman";
  color: #000000;
  -webkit-text-stroke: #000000;
}
p.p7 {
  margin: 0px 0px 16px 0px;
  text-align: justify;
  font: 13.3px Times;
  color: #000000;
  -webkit-text-stroke: #000000;
}
p.p8 {
  margin: 0px 0px 16px 0px;
  text-align: justify;
  text-indent: 96px;
  font: 13.3px "Times New Roman";
  color: #000000;
  -webkit-text-stroke: #000000;
}
p.p9 {
  margin: 0px 0px 0px 0px;
  text-align: justify;
  font: 13.3px "Times New Roman";
  color: #000000;
  -webkit-text-stroke: #000000;
}
p.p10 {
  margin: 0px 0px 0px 48px;
  font: 13.3px "Times New Roman";
  color: #000000;
  -webkit-text-stroke: #000000;
}
span.s1 {
  font-kerning: none;
}
span.s2 {
  font: 13.3px "Times New Roman";
  font-kerning: none;
}
span.s3 {
  font: 13.3px Times;
  font-kerning: none;
}
span.s4 {
  font: 9.3px "Times New Roman";
  font-kerning: none;
}
span.s5 {
  font: 13.3px "Times New Roman";
  font-kerning: none;
  color: #0000ff;
  -webkit-text-stroke: 0px #0000ff;
}
span.s6 {
  font-kerning: none;
}
