.wrapper {
  padding: 0em 3em 5em;
  z-index: 200;
  margin-left: 160px; /*Same as the width of the sidebar*/
    
}

.centered {
  text-align: center;
}

#appHeader {
  padding: 1em 0;
  background-color: white;
  margin: 0.25em 0.5em 0.5em;
}

.whiteSpace {
  white-space: pre-line;
}

#buttons {
  padding: 2em 0 4em;
}

@media screen and (max-width: 700px) {
  .wrapper {margin-left: 60px;}
  .sidenav a {font-size: 18px;}
}