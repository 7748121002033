.logoGreen {
  color: white;
  padding: 1em 0 1.5em;
  font-size: 3em;
  width: 100%;
}

.centered {
  text-align: center;
}

#mainLogo {
  text-align: center;
}

#homeHeader {
  color: white;
  font-size: 1em;
}

#homeSubtitle {
  color: white;
}

/* .logoBlue {
  padding: 5em 4em;
} */
.logoBlue {
  background-color: rgb(51, 187, 238);
  color: white;
  padding: 1em 3.5em 1.5em;
  font-size: 3em;
  width: 100%;
}

.logoSoftBlue {
  background-color: rgba(51, 188, 238, 0.65);
  padding: 1em 3em 1.5em;
  text-align: center;
}

#org {
  font-size: 2em;
}

#org1 {
  font-size: 2em;
}

#track {
  font-size: 1.33em;
}

#snapshot {
  font-size: 1.33em;
}

#buttons {
  padding-top: 3em;
}
