.tipButton {
  margin-top: "-10px";
  margin-right: "-10px";
  position: "absolute";
  top: 0;
  right: 0;
}

.tipsContainer {
  margin-left: 160px;
}
