Fragment {
  position: relative;
  z-index: 2;
}

#dateApplied {
  padding-bottom: 1em;
}

#impDate {
  width: 50%;
  float: left;
}

#onDate {
  width: 50%;
  float: left;
  margin-bottom: 10px;
}

#wrap {
  padding: 0em 10% 5em;
}

.logoHeader {
  text-align: center;
}

#iconHeader {
  margin: 0.25em 1em 0.75em;
}

#contain {
  margin-bottom: 50px;
}

#addFormContainer {
  padding: 1em 0;
  background-color: white;
}

.addAppIconHeader {
  margin: 0.25em 1em 2em;
}

.cancelButton {
  padding: 0 0 4em;
}

.chq-atc--dropdown {
  width: fit-content !important;
}
