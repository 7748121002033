p.p1 {
    margin: 15px 10px 10px 10px;
    text-align: center;
    font: 24px Arial;
    font-weight: 450;
    color: #000000;
    -webkit-text-stroke: #000000;
    /* background-color: rgb(169, 217, 175); */
  }
  p.p2 {
    margin: 0px 0px 0px 0px;
    font: 18px "Times New Roman";
    font-weight: 250;
    text-align: justify;
    color: #000000;
    -webkit-text-stroke: #000000;
    /* background-color: rgb(169, 217, 175); */
  }
  
  span.s1 {
    font-kerning: none;
  }
  span.s2 {
    font: 16px "Times New Roman";
    font-kerning: none;
  }
  
  