#foot {
  padding: 1em 5em;
  text-align: center;
  width: 100%;
  z-index: 1;
}

.stickyFooter {
  position: -webkit-sticky;
  position: fixed;
  bottom: 0;
}



.bp {
  padding-bottom: 1.5em;
}

.ui.horizontal.bulleted.list > .item::before {
  opacity: 0;
}

@media screen and (max-width: 700px) {
  .stickyFooter {font-size: 1px;}
  
}
