/* The sidebar menu */
.sidenav {
    /* height: 100%;  */
    /* Full-height: remove this if you want "auto" height */
    width: 120px; /* Set the width of the sidebar */
    position: absolute; /* Fixed Sidebar (stay in place on scroll) */
    z-index: 1; /* Stay on top */
    top: 1; 
    left: 0;
    /* background-color: rgba(51, 204, 102, 0.884);  */
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 20px;
  }
  
  /* The navigation menu links */
  .sidenav a {
    padding: 6px 8px 16px 16px;
    text-decoration: none;
    font-size: 14px;
    font-weight: 600;
    color: #000000;
    display: block;
  }
  
  /* When you mouse over the navigation links, change their color */
  .sidenav a:hover {
    color: #e020b0;
  }
  
  /* Style page content */
  .main {
    margin-left: 160px; /* Same as the width of the sidebar */
    padding: 0px 10px;
  }
  
  /* On smaller screens, where height is less than 450px, change the style of the sidebar (less padding and a smaller font size) */
  @media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
  }

  @media screen and (max-width: 700px) {
    .main {margin-left: 100px;}
  }

  #compName {
    padding-left: 0.5em;
    vertical-align: 30%;
  }