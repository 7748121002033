#title {
  color: white;
  font-weight: 700;
  padding-bottom: 0.5em;
}

#lg {
  padding: 15px 30px;
  font-size: 18px;

}

#arrow {
  margin-left: -1rem;
  padding-right: 1em;
  vertical-align: 40%;
}

#compName {
  padding-left: 0.5em;
  vertical-align: 40%;
}

#btn {
  float: right;
}
