p.p1 {
  margin: 0px 0px 0px 0px;
  text-align: center;
  font: 14.7px Arial;
  color: #000000;
  -webkit-text-stroke: #000000;
}
p.p2 {
  margin: 0px 0px 0px 0px;
  font: 16px "Times New Roman";
  color: #000000;
  -webkit-text-stroke: #000000;
}
p.p3 {
  margin: 0px 0px 0px 0px;
  font: 14.7px Arial;
  color: #000000;
  -webkit-text-stroke: #000000;
}
p.p4 {
  margin: 0px 0px 0px 48px;
  font: 16px "Times New Roman";
  color: #000000;
  -webkit-text-stroke: #000000;
}
p.p5 {
  margin: 0px 0px 0px 48px;
  font: 14.7px Arial;
  color: #000000;
  -webkit-text-stroke: #000000;
}
p.p6 {
  margin: 0px 0px 0px 96px;
  font: 16px "Times New Roman";
  color: #000000;
  -webkit-text-stroke: #000000;
}
p.p7 {
  margin: 0px 0px 0px 48px;
  text-indent: 24px;
  font: 14.7px Arial;
  color: #000000;
  -webkit-text-stroke: #000000;
}
p.p8 {
  margin: 0px 0px 0px 96px;
  text-indent: 24px;
  font: 14.7px Arial;
  color: #000000;
  -webkit-text-stroke: #000000;
}
span.s1 {
  font-kerning: none;
}
span.s2 {
  font: 16px "Times New Roman";
  font-kerning: none;
}
span.s3 {
  font: 14.7px Arial;
  font-kerning: none;
}
span.s4 {
  font: 14.7px "Lucida Grande";
  font-kerning: none;
}
span.s5 {
  font: 9.3px "Times New Roman";
  font-kerning: none;
}
span.s6 {
  font: 14.7px "Courier New";
  font-kerning: none;
}
