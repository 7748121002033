body {
  background-color: rgba(51, 204, 102, 0.204);
}

.boardWrapper {
  padding: 30px 10px;
  overflow: auto;
  min-height: 100%;
  position: relative;
  z-index: 200;
}

.centered {
  text-align: center;
}

.main {
  margin-left: 160px; /* Same as the width of the sidebar */
  padding: 0px 10px;
}

#head {
  text-align: center;
  padding-bottom: 3em;
}

#jobBoard {
  padding-left: 0.5em;
  vertical-align: 50%;
  font-weight: 700;
  font-size: 2em;
}

#select {
  margin: 1em 20%;
}


