.paddedRow {
  padding: 2em;
}

.howToWrapper {
  padding: 3em 2em;
}

.centered {
  text-align: center;
}

#pageTitle {
  padding-bottom: 1em;
}

#titleText {
  padding-left: 0.5em;
  vertical-align: 50%;
  font-weight: 700;
  font-size: 2em;
  color: black;
}
