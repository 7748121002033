.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.logoBlue {
  background-color: rgb(51, 187, 238);
}

.logoSoftBlue {
  background-color: rgba(51, 188, 238, 0.65);
}

.logoSoftGreen {
  background-color: rgb(217, 244, 222);
}

.logoSoftGreen2 {
  background-color: rgb(161, 214, 179);
}

.logoGreen {
  background-color: rgba(51, 204, 102, 0.884);
}

.logoGreen1 {
  background-color: rgba(145, 196, 173, 0.6);
}

.logoSoftGreen3 {
  background-color: rgba(51, 204, 102, 0.256);
}

.logoSoftGreen4 {
  background-color: rgba(51, 204, 102, 0.204);
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
