.forms {
  padding: 2em 15% 5em 15%;
}

#welcome {
  text-align: center;
  padding: 2em;
}

#loginButton {
  padding: 1em;
  text-align: center;
}

#btn {
  margin-bottom: 1em;
}

#noAccount {
  padding: 0.5em 0 1em;
  text-align: center;
}

#newInput {
  padding: 0 2rem 2rem;
}

#logoHead {
  padding-top: 20px;
}

#entry {
  padding: 2em 5em;
}

#recovery {
  font-weight: 500;
}
